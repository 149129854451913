import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            ph="7%"
            pt="10px"
            pb="5px"
            bg="rgb(0,0,0,.8)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column center>
              {/* <CFText color="white" h1 raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText> */}
            </CFView>
            <CFView mt="5px" mb="10px">
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="10px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            column
            center
            w="100%"
            pv="10px"
            maxWidth="1400px"
            bg="rgba(0, 0, 0, 0.8)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView column justifyStart mb="5px">
              <CFText
                color="white"
                style={{
                  fontSize: 36,
                }}
                raleway
                bold
              >
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center pt="5px">
              <OrderPickupButton />
              <CFView ml="15px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
