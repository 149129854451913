import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dispatch } from 'store'
import { orderDeliveryButton } from 'images'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://www.skipthedishes.com/sushico-japanese-restaurant-222nd-eagle-landing-parkway?utm_source=google&utm_medium=organic&utm_campaign=gpa`}
            >
              <CFView hover>
                <CFImage
                  src={orderDeliveryButton}
                  maxWidth="300px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://www.skipthedishes.com/sushico-japanese-restaurant-222nd-eagle-landing-parkway?utm_source=google&utm_medium=organic&utm_campaign=gpa`}
            >
              <CFView hover w="90%">
                <CFImage
                  src={orderDeliveryButton}
                  maxWidth="280px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
